import React from "react";
import styled from "styled-components";
import withLayout from "../../layout";
import {FormattedMessage, injectIntl} from "react-intl";
//COMPONENTS
import SimpleStats from "../../components/UI/SimpleStats";
import Image from "../../components/UI/Image";
//GENERIC STYLE IMPORT
import {Heading, Paragraph} from "../../components/UI/Typography";
import DropBottom from '../../images/case_studies/fiorentina/DropBottom.svg';
import DropUp from '../../images/case_studies/fiorentina/DropUp.svg';
import BKGImg from "../../images/case_studies/fiorentina/banner-fiorentina.jpg";
import HeroCaseStudy from "../../components/HeroCaseStudy";
import LottieController from "../../components/UI/LottieController";
import {useInView} from "react-intersection-observer";
import DropBg from "../../components/UI/DropBg";
import DropBig1 from '../../images/case_studies/acea/DropBig1.svg';
import DropBig2 from '../../images/case_studies/acea/DropTopLeftM.svg';
import Graph1 from '../../components/UI/Lottie/Colleghi.json';
import SEO from "../../components/SEO";
import Result2 from '../../components/UI/Lottie/Euro.json';
import LutherLogo from '../../images/case_studies/enel/luther_logo.svg';
import {Fade} from 'react-reveal';
import useWindowSize from "../../hooks/useWindowSize";
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage";
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory";
import SectionTitle from "../../components/CaseStudy/SectionTitle";
import Results from "../../components/CaseStudy/Results";
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"

const FiorentinaCaseStudy = ({intl}) => {
    const [graphView1, inViewG1] = useInView();
    const [graphView2, inViewG2] = useInView();
    const keywords = intl.formatMessage({id: 'fiorentina.meta_keywords'}).split(',') || [];
    const {width} = useWindowSize();

    return (
        <>
            <SEO title={intl.formatMessage({id: 'fiorentina.meta_title'})}
                 description={intl.formatMessage({id: 'fiorentina.meta_description'})} keywords={keywords}
                 lang={intl.locale}/>
            <HeroCaseStudy heroImage={'case_studies/fiorentina/Header-fiorentina.png'} title={'fiorentina.title'}
                           subtitle1={'fiorentina.subtitle'}
                           hasPartner={true} partnerLogo={LutherLogo} intl={intl}
                           color={'linear-gradient(23.36deg, #60269E 16.59%, #8251B7 87.97%)'}
                           categories={['portfolio.filters.design_dev']}/>
            <div className="section-padding">
                <DropBg src={DropBig1} left={'0%'} zIndex={'-1'} bottom={'-20%'} className="d-none d-lg-block"/>
                <DropBg src={DropUp} bottom={'5%'} left={'25%'} className="d-none d-lg-block"/>
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'01'} text={'fiorentina.overview_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className={"col-md-5 col-12"}>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"fiorentina.client_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"fiorentina.client_1"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className={"col-md-6 col-12 offset-md-1"}>
                                <Heading level={"4"} className="pt-2">
                                    <FormattedMessage id={"fiorentina.client_needs"}/>
                                </Heading>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"fiorentina.client_needs_1"}/>
                                </Paragraph>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"fiorentina.client_needs_1_2"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <FullWidthImage image={BKGImg} imageWidth={1920} imageHeight={936}/>
            <div className="section-padding">
                <DropBg src={DropBig2} right={'-10%'} zIndex={'-1'} top={'15%'} className="d-none d-lg-block"/>
                <DropBg src={DropBottom} top={'10%'} right={'10%'} className="d-none d-lg-block"/>
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 d-none d-md-block">
                                <SectionTitle number={'02'} text={'fiorentina.brief_title'}/>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <Heading level={"2"} margin={'0px'}>
                                    <FormattedMessage id={"fiorentina.service_big_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id="fiorentina.services_txt_1"/>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'03'} text={'fiorentina.workflow_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row ">
                        <Fade bottom delay={400}>
                            <div className="col-md-7 col-sm-12">
                                <Heading level={"3"}>
                                    <FormattedMessage id={"fiorentina.solutions_big_title"}/>
                                </Heading>
                                <Heading level={"4"}>
                                    <FormattedMessage id={"fiorentina.solutions_title_1"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"fiorentina.solutions_txt_1"}/>
                                </Paragraph>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"fiorentina.solutions_txt_1_2"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-md-4 col-sm-12 offset-0 offset-md-1 position-relative">
                                <Image filename={'case_studies/fiorentina/Body-fiorentina.png'} alt={'body Fiorentina'}
                                       className={'case-study-right-pic'}/>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="section-padding-top">
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                            <Heading level={"4"}>
                                    <FormattedMessage id={"fiorentina.solutions_title_2"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"fiorentina.solutions_txt_2"}/>
                                </Paragraph>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"fiorentina.solutions_txt_2_2"}/>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className="section-padding-custom">
                <div className="container">
                    <div className="row align-items-start">
                        <Fade bottom delay={400}>
                            <div className="col-lg-6 col-12">
                                <Image filename={'case_studies/fiorentina/Board-fiorentina.jpg'}
                                       alt={'board Fiorentina'} style={{left: width < 992 ? '0%' : '-35%'}}/>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-lg-6 mt-5 mt-lg-0 col-12">
                                <Paragraph>
                                    <FormattedMessage id={"fiorentina.solutions_txt_3"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <Results
                subtitle="fiorentina.dark_section_paragraph"
                testimonialQuote="fiorentina.dark_section_quote"
                testimonialImg="carlo_angeletti.jpg"
                testimonialName="fiorentina.dark_section_testimonial_name"
                testimonialRole="fiorentina.dark_section_testimonial_desc"
            >
                <div className="col-12 col-lg-3">
                    <div ref={graphView1}>
                        <LottieController
                            lottieJSON={Graph1}
                            inView={inViewG1}
                            height="100px"
                            width="122px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG1}
                            valueFrom={0}
                            valueTo={100}
                            prefix="+"
                            headingStyle={{fontWeight: 500}}
                            label="fiorentina.stats_1"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView2}>
                        <LottieController
                            lottieJSON={Result2}
                            inView={inViewG2}
                            height="98px"
                            width="208px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG2}
                            valueFrom={0}
                            valueTo={35}
                            measureUnit="%"
                            label="fiorentina.stats_2"
                            headingLevel="1"
                            labelColor="white"
                            headingStyle={{fontWeight: 500}}
                        />
                    </div>
                </div>
            </Results>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="fiorentina.next_success_case_title"
                desc="fiorentina.next_success_case_description"
                link="/case-study/translated/"
                intl={intl}
            />
        </>
    )
}

const customProps = {
    localeKey: "fiorentina", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(FiorentinaCaseStudy))
